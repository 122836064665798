<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            required: false,
            default: () => "center",
        },
    },
    data() {
        return {
            baseClasses: "px-4 sm:px-6 lg:px-8",
            alignments: {
                left: "mr-auto",
                center: "mx-auto",
                right: "ml-auto",
            },
        };
    },
    computed: {
        classes() {
            return `container ${this.baseClasses} ${this.alignments[this.align]}`;
        },
    },
};
</script>
